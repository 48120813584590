@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600&family=Mate+SC&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app{
  font-family: 'Crimson Text', serif;
  padding: 10px;
}

p{
  font-size: 19px;
}

label{
  font-size: 17px;
}

.afterServiceContainer img, .beforeServiceContainer img{
    width: 300px;
    height: 250px;
    margin: 20px;
    box-shadow: 0px 3px 10px -6px;
}

.afterServiceContainer, .beforeServiceContainer {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
}

@media only screen and (min-width: 650px) {
.afterServiceContainer img, .beforeServiceContainer img{
  width:330px;
  height: 250px;
}
}

@media only screen and (min-width: 800px) {
.afterServiceContainer img, .beforeServiceContainer img{
  width:500px;
  height: 350px;
}

.afterServiceContainer, .beforeServiceContainer {
display: -webkit-flex;
display: flex;
-webkit-justify-content: center;
        justify-content: center;
}
}
.contact{
     display: -webkit-flex;
     display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

form{
     margin: 10px;
}

.contact h1 {
     text-align: center;
}

.contact h2, .mail p{
     margin: 10px;
}

form{
     margin-bottom: 30px;
}

form div{
     display: -webkit-flex;
     display: flex;
     -webkit-justify-content: center;
             justify-content: center;
     -webkit-flex-direction: column;
             flex-direction: column;
     margin: 5px 0px;
}

#messageBox{
     height: 100px;
     width: 99%;
}

@media only screen and (min-width: 650px) {

     .subContact{
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-align-items: center;
                  align-items: center;
     }

     form div{
          width: 600px;
     }
}

@media only screen and (min-width: 900px) {

     .subContact{
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: flex-start;
                  align-items: flex-start;
          -webkit-flex-direction: row;
                  flex-direction: row;
          -webkit-justify-content: center;
                  justify-content: center;
     }

     .mail{
          padding: 75px 50px 50px 100px;
     }
}

.home{
  padding: 20px 0px;
}

#homeImg{
    width: 100%;
    box-shadow: 0px 3px 10px -6px;
}

.mainQoute {
  text-align: center;
}

.mainQoute h4 {
  font-size: 19px;
  line-height: 40px;
}

blockquote h4:before {
  content: "\201C""\201D";
  /* text-align: center; */
  display: inline;
  font-size: 1.2em;
  line-height: 1em;
  vertical-align: center;
  height: 0.4em;
  color: rgba(36, 36, 36, 0.4);
}

blockquote h4:after {
  content: "\2019""\2019";
    font-size: 1.2em;
  line-height: 1em;
  vertical-align: center;
  height: 0.5em;
  color: rgba(36, 36, 36, 0.4);
}

@media only screen and (min-width: 650px) {
  /****************\\\\\\\\\ Tablet /////////****************/

  #homeImg{
    width: 95%;
}

.headImgContainer{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}
}

@media only screen and (min-width: 1000px) {
  /****************\\\\\\\\\ Tablet /////////****************/

  #homeImg{
    height: 66vh;
}
}

@media only screen and (min-width: 1500px) {
  /****************\\\\\\\\\ Tablet /////////****************/

  #homeImg{
    height: 600px;
}
}

header{
display: -webkit-flex;
display: flex;
-webkit-justify-content: space-between;
        justify-content: space-between;
padding-bottom: 20px;
}

#logo{
    height: 125px;
}

#companyName{
display: -webkit-flex;
display: flex;
-webkit-align-self: flex-end;
        align-self: flex-end;
width: 230px;
height: 27px;
padding-left: 10px;
}

.logoContainer{
  display: -webkit-flex;
  display: flex;
}

@media only screen and (min-width: 650px) {
  /****************\\\\\\\\\ Tablet /////////****************/

header{
    padding-left: 10px;
}

#logo{
    height: 140px;
    padding-left: 10px;
}

#companyName{
    width: 350px;
    height: 30px;
}
}

@media only screen and (min-width: 800px) {

.logoContainer{
  display: -webkit-flex;
  display: flex;
}

header{
    padding-left: 15px;
}
}

@media only screen and (min-width: 1000px) {
  
header{
    padding-left: 23px;
}
}

@media only screen and (min-width: 1200px) {

header{
    padding-left: 25px;
}
}

@media only screen and (min-width: 1400px) {

header{
    padding-left: 35px;
}
}
.story{
             padding: 10px 0px 30px 0px;
}

.ourStory h1, .ourStory h2{
     text-align: center;
}

#thePromise{
text-align: center;
font-size: 25px;
padding-bottom: 40px;
}  

#storyPhone{
     font-size: 28px;
}

@media only screen and (min-width: 1000px) {   

.story p{
    width: 980px;
}

.story{
display: -webkit-flex;
display: flex;
-webkit-flex-direction: column;
        flex-direction: column;
-webkit-align-items: center;
        align-items: center;
}

}
.services{
     padding: 50px 0px;
}
ul{
     margin: 0;
     list-style: none;
}

li{
     font-size: 18px;
}

#servicesText{
         margin: 10px;
}

#points{
-webkit-align-content: center;
        align-content: center;
width: 100%;
}

@media only screen and (min-width: 600px) {
#points{
display: -webkit-flex;
display: flex;
width: 100%;
-webkit-justify-content: space-evenly;
        justify-content: space-evenly;
}

}

@media only screen and (min-width: 1000px) {

     .textContainer{
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-align-items: center;
                  align-items: center;
     }

     #servicesText{
          width: 980px;
     }

     #points{
     display: -webkit-flex;
     display: flex;
     width: 100%;
     -webkit-justify-content: space-evenly;
             justify-content: space-evenly;
     }

}
footer p{
    font-size: 18px;
    margin: 5px 0;
}

.miscInfo{
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: 10px;
}
