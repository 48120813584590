header{
display: flex;
justify-content: space-between;
padding-bottom: 20px;
}

#logo{
    height: 125px;
}

#companyName{
display: flex;
align-self: flex-end;
width: 230px;
height: 27px;
padding-left: 10px;
}

.logoContainer{
  display: flex;
}

@media only screen and (min-width: 650px) {
  /****************\\\\\\\\\ Tablet /////////****************/

header{
    padding-left: 10px;
}

#logo{
    height: 140px;
    padding-left: 10px;
}

#companyName{
    width: 350px;
    height: 30px;
}
}

@media only screen and (min-width: 800px) {

.logoContainer{
  display: flex;
}

header{
    padding-left: 15px;
}
}

@media only screen and (min-width: 1000px) {
  
header{
    padding-left: 23px;
}
}

@media only screen and (min-width: 1200px) {

header{
    padding-left: 25px;
}
}

@media only screen and (min-width: 1400px) {

header{
    padding-left: 35px;
}
}