.story{
             padding: 10px 0px 30px 0px;
}

.ourStory h1, .ourStory h2{
     text-align: center;
}

#thePromise{
text-align: center;
font-size: 25px;
padding-bottom: 40px;
}  

#storyPhone{
     font-size: 28px;
}

@media only screen and (min-width: 1000px) {   

.story p{
    width: 980px;
}

.story{
display: flex;
flex-direction: column;
align-items: center;
}

}