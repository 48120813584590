.services{
     padding: 50px 0px;
}
ul{
     margin: 0;
     list-style: none;
}

li{
     font-size: 18px;
}

#servicesText{
         margin: 10px;
}

#points{
align-content: center;
width: 100%;
}

@media only screen and (min-width: 600px) {
#points{
display: flex;
width: 100%;
justify-content: space-evenly;
}

}

@media only screen and (min-width: 1000px) {

     .textContainer{
          display: flex;
          flex-direction: column;
          align-items: center;
     }

     #servicesText{
          width: 980px;
     }

     #points{
     display: flex;
     width: 100%;
     justify-content: space-evenly;
     }

}