.contact{
     display: flex;
    justify-content: space-evenly;
}

form{
     margin: 10px;
}

.contact h1 {
     text-align: center;
}

.contact h2, .mail p{
     margin: 10px;
}

form{
     margin-bottom: 30px;
}

form div{
     display: flex;
     justify-content: center;
     flex-direction: column;
     margin: 5px 0px;
}

#messageBox{
     height: 100px;
     width: 99%;
}

@media only screen and (min-width: 650px) {

     .subContact{
          display: flex;
          flex-direction: column;
          align-items: center;
     }

     form div{
          width: 600px;
     }
}

@media only screen and (min-width: 900px) {

     .subContact{
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          justify-content: center;
     }

     .mail{
          padding: 75px 50px 50px 100px;
     }
}
